import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Trailguide vokser`}</h4>
    <p>{`Teknologiselskapet Trailguide har hentet inn ny kapital og utvider ansattstaben.
Det betyr flere ressurser til å videreutvikle tjenesten samt få teknologien ut i
markedet. Nå ønsker de å se hvor langt de kan nå.`}</p>
    <Image src="/news/trailguide_ferry.jpg" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`Løsninger på problemer`}</h4>
    <p>{`Ideen om Trailguide ble født på tur da syklist, gründer og programmerer Bjørn
Jarle Kvande syns det var unødvendig utfordrende å finne gode stier å sykle på.
Løsningen ble en app hvor syklister kunne finne og dele stier med hverandre.`}</p>
    <br />
    <p>{`I 2013 startet prosjektet med å bygge opp brukerbasen ved å reise rundt i Norge
og store deler av verden. I 2017 startet utviklingen av teknologien for alvor,
samtidig som Trailguide ansatte tyskeren Peter Seidl. Peter fikk ansvar for
brukervekst, markedsføring og salg og er også hjernen bak det nye Trailguide-kartet
som ble lansert tidlig 2020.`}</p>
    <Image src="/news/uploadtrails_6.jpg" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`Solid plattform for deling av utendørsaktiviteter`}</h4>
    <p>{`Med grundig informasjon om brukernes behov og ønsker har Trailguide utviklet seg
til å bli en solid plattform av og for syklister. Det som startet som en idè for
å finne og dele sykkelstier, blir nå videreutviklet til å inkludere andre aktiviteter,
og vi oppdager stadig nye muligheter. Gjennom Trailguide kan brukerne dele og finne
fin sykling på både sti, grus og asfalt, finne pumptracks, gåturer, toppturer,
klatring etc. Per nå har vi et rikt utvalg av sykkelrelatert innhold, og vi jobber
hver dag med å videreutvikle teknologien slik at den blir best mulig.`}</p>
    <br />
    <p>{`Så langt er det mulig å finne og dele stiturer, blandevei, landevei, pumptrack,
dirtparker og gåturer. Etter hvert som vi får andre aktiviteter oppe å gå, kan
brukerene dele andre utendørsaktiviteter også, forteller Bjørn Jarle Kvande.`}</p>
    <Image src="/tonje/tonje-portrait.jpg" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`Mer kapital og flere ansatte i 2021`}</h4>
    <p>{`Med ny kapital fra Gol kommune, ekstern investor og Innovasjon Norge får vi rom
for å utvide staben vår med en ny dedikert daglig leder og en utvikler til.
Vi ønsker også å ansette enda flere programmerere i løpet av 2021 for å styrke
utviklingen av teknologien enda mer og få teknologien ut i markedet. Mer kapital
bidrar til at vi får enda flere ressurser til utvikling av Trailguide, samt få
teknologien ut i markedet.`}</p>
    <br />
    <p>{`Den nyansatte daglige lederen av 1. desember 2020 - Tonje Sameien, har tidligere
jobbet som prosjektleder i Fri Flyt AS med ansvar for sykkelrelaterte arrangement,
blant annet terrengsykkelfestivalen Utflukt i Trysil.`}</p>
    <Image src="/solutions/presentation.png" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`Bedre informasjon og tilgjengeliggjøring av utendørsaktiviteter`}</h4>
    <p>{`Trailguide kan løse mange utfordringer som destinasjoner har. Et digitalt kart kan
sørge for at informasjonen om destinasjonen og dens aktivitetstilbud er enklere å
oppdatere, samt mer tilgjengelig for brukeren. Den besøkende trenger ikke laste ned,
installere eller registrere seg i en app. Gjennom en QR-kode gis umiddelbar tilgang
til funksjonaliteten. QR-kodene kan formidles eksempelvis på fysiske oppslagskart,
gjennom klistremerker på resepsjonsdisken eller utlånssykler. Rask og enkel
brukervennlighet er nøkkelen.`}</p>
    <br />
    <p>{`Gjennom Trailguide vil destinasjonen også kunne tilby besøkende et brukervennlig
kart, enkel navigasjon samt en kanal for tilbakemeldinger om føreforhold, status
på stier o.l. Når denne informasjonen er tilgjengelig digitalt, fører det også til
at det er enklere, raskere og billigere å endre informasjonen. Gjennom Trailguide
kan altså hver enkelt destinasjon lage sine egne digitale kart med innhold som de
selv ønsker å formidle.`}</p>
    <br />
    <p>{`Teknologien er solid og med et selskap i vekst, er det ganske spennende å se inn i
fremtiden. For å utvikle Trailguide er vi avhengig av et godt samarbeid med
destinasjoner, som kan være med på å forme teknologien videre gjennom erfaringer
ved å ta i bruk teknologien for sin destinasjon. Jeg overbevist om at Trailguide
vil være et veldig bra verktøy for å synliggjøre destinasjoner og deres tilbud,
sier Tonje Sameien – daglig leder i Trailguide.`}</p>
    <Image src="/solutions/accessinfodark2.png" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`Ikke bare for stisykling`}</h4>
    <p>{`Trailguide startet som et digitalt verktøy hvor syklister kunne dele og finne stier.
Løsningen er derimot videreutviklet til å inkludere andre aktiviteter og vi oppdager
stadig nye muligheter med teknologien. Per nå har vi et rikt utvalg av sykkelstier,
men også forslag for de som ønsker å finne turforslag til fots.`}</p>
    <Image src="/solutions/presentactivities_black3.png" className="w-full mx-auto my-12" mdxType="Image" />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      